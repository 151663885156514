<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData" class="mb-20">

			<a-row :gutter="24" class="px-10 pt-10" style="margin-top: 0px;">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">
					<a-card class="p-0 m-0 pb-15" style="">
                        <a-row :gutter="24" class="pt-10">
                            <a-col :span="24" :sm="24" :md="24" :lg="8" :xl="8">
                                <a-card class="px-0 mb-20">
                                    <a-row :gutter="24">
                                        <a-col :span="24" :md="24">
                                            <div class="icon">
                                                <img id="classImg" width="100%" height="400px" :src="classDetails.profile != null ? classDetails.profile : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                            </div>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="24">
                                        <a-col :span="24" :md="24" >
                                            <h5 class="mt-10 pt-10 pl-2 mb-5">{{ formatName(classDetails.firstName) }} {{ formatName(classDetails.lastName) }}</h5>
                                    
                                            <svg v-for="(option, index) in 4" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                            </svg>
                                            <svg class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                            </svg>
                                        </a-col>

                                        <a-col :span="24" :md="24" class="mt-5">
                                            <span class="">
                                                Last seen: <strong>{{ $Moment(classDetails.lastSeen).format("DD MMM YYYY") }}</strong>
                                            </span>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="24" class="mt-10">

                                        <a-col :span="16" class="mt-10">
                                            <a-button type="primary" block @click="toBookingPage">Book Tutor</a-button>
                                        </a-col>

                                        <a-col :span="8" class="mt-10">
                                            <a-button id="textBtn" type="#2AA81A" block @click="copyUrl">
                                                <svg class="dark:text-white" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m8 10.9 7-3.2m-7 5.4 7 3.2M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/>
                                                </svg>
                                                Share
                                            </a-button>
                                        </a-col>

                                    </a-row>
                                </a-card>

                            </a-col>
                            <a-col :span="24" :sm="24" :md="24" :lg="16" :xl="16">
                                
                                <a-card>
                                    <a-row :gutter="24" class="mt-10">
                                        <a-col :span="24" class="mt-0">
                                            <label style="font-size: 18px;" class="mt-10"><strong>About me</strong></label>
                                            <p class="mt-15">{{ classDetails.biography }}</p>
                                        </a-col>
                                    </a-row>
                                </a-card>

                                <a-card class="mt-20">

                                    <a-row :gutter="24" class="mt-10">
                                        <a-col :span="24" class="mt-0">
                                            <label style="font-size: 18px;" class="mt-10"><strong>Tutoring Details</strong></label>
                                        </a-col>
                                    </a-row>

                                    <a-row :gutter="24" class="mt-15">
                                        <a-col :span="24" class="mt-0">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Grades I teach</strong></label><br/>
                                            <a-badge  
                                                v-for="option in classDetails.educationGrades"
                                                :key="option.uuid"
                                                style="font-size: 14px !important;" 
                                                :count="`📖 ${option.name}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ classDetails.educationGrades.length == 0 ? 'N/A' : ''}}
                                        </a-col>

                                        <a-col :span="24" class="mt-15">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Subjects I teach</strong></label><br/>
                                            <a-badge  
                                                v-for="option in classDetails.subjects"
                                                :key="option.uuid"
                                                style="font-size: 14px !important;" 
                                                :count="`📖 ${option.name}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ classDetails.subjects.length == 0 ? 'N/A' : ''}}
                                        </a-col>

                                        <a-col :span="24" class="mt-15">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Mode of teaching</strong></label><br/>
                                            <a-badge  
                                                v-for="option in classDetails.teachingModes"
                                                :key="option"
                                                style="font-size: 14px !important;" 
                                                :count="`📖 ${option}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ classDetails.teachingModes.length == 0 ? 'N/A' : ''}}
                                        </a-col>

                                        <a-col :span="24" class="mt-15">
                                            <label style="font-size: 14px;" class="mt-10"><strong>Days I teach</strong></label><br/>
                                            <a-badge  
                                                v-for="option in classDetails.days"
                                                :key="option"
                                                style="font-size: 14px !important;" 
                                                :count="`${formatName(option)}`" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px', margin: '5px 5px'}"/>
                                                {{ classDetails.days.length == 0 ? 'N/A' : ''}}
                                        </a-col>
                                    </a-row>
                                
                                </a-card>
                                
                            </a-col>
                        </a-row>
                    </a-card>
				</a-col>

                <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-20 pt-5 pb-20">
                    
                    <a-card class="pb-20" style="">
                        <a-row :gutter="24" class="mt-10 px-20">
                            <a-col :span="24" class="mb-20 pb-20 px-20">
                                <label style="font-size: 18px;" class="mt-10"><strong>My Tutoring Classes</strong></label>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24" class="px-20">
                            <a-col :span="24"  :md="6" :lg="6" :xl="6" class="mb-24 pb-10" v-for="clas in classDetails.classes" :key="clas.uuid">
                                <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15">
                                    <a class="text-dark p-0 m-0" style="text-decoration: none !important;" :href="'/classes/view/' + clas.uuid">
                                        <a-row :gutter="24">
                                            <a-col :span="24">
                                                <div class="icon">
                                                    <img id="classImg" width="100%" height="200px" :src="clas.thumbnail != null ? clas.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                                </div>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="24" class="px-20">
                                            <a-col :span="20">
                                                <h6 class="mt-10 pt-4 pl-2">{{ clas.name.length > 20 ? `${clas.name.substring(0, 20)}...` : clas.name }}</h6>
                                            </a-col>

                                            <a-col :span="4">
                                                <svg class="mt-10 pt-5 text-gray-800 dark:text-white" style="width: 18px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z"/>
                                                </svg>
                                            </a-col>

                                            
                                        </a-row>

                                        <a-row :gutter="24" class="px-20">
                                            <a-col :span="24" :md="16" :lg="16" :xl="16">
                                                <!-- <a-avatar :src="clas.creator != null && clas.creator.profilePicUrl ? clas.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                                
                                                <a-row :gutter="24" class="">
                                                    <a-col :span="24" :md="24" :lg="24" :xl="24">
                                                        {{ clas.creator != null ? clas.creator.firstName.length + clas.creator.lastName.length > 15 ? `${formatName(clas.creator.firstName)} .${clas.creator.lastName[0].toUpperCase()}` : `${formatName(clas.creator.firstName)} ${formatName(clas.creator.lastName)}` : 'Unknown'}}, Teacher
                                                    </a-col>
                                                    <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                                        <svg v-for="(option, index) in 4" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                            <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                                        </svg>
                                                        <svg class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                                        </svg>
                                                    </a-col>
                                                </a-row>

                                            </a-col>
                                            <a-col :span="24" :md="8" :lg="8" :xl="8" class="text-right">
                                                <h6 class="text-silabu pt-10">{{ clas.price != null && clas.price != 0 ? `${clas.price}/=` : 'FREE' }}</h6>
                                            </a-col>
                                        </a-row>

                                        <!-- <a-row :gutter="24" class="mt-10 px-20">
                                            <a-col :span="24" :md="14" :lg="14" :xl="14">
                                                <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                                <span><strong>{{ clas.slots + 1 - clas.membersCount }}</strong></span> <small>Slots remain </small>
                                            </a-col>

                                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                                <h6 class="text-silabu pr-5">{{ clas.price != null && clas.price != 0 ? `${clas.price}/=` : 'FREE' }}</h6>
                                            </a-col>
                                        </a-row> -->
                                    </a>
                                </a-card>
                            </a-col>

                        </a-row>
                    </a-card>
                </a-col>

			</a-row>

            <a-row :gutter="24">
                <a-col :span="24">
                    <a-row :gutter="24" class="px-20">

                        
                        </a-row>
                </a-col>
            </a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                activeTab: 1,
				classes: [],
                classDetails: {
                    uuid: null,
                    firstName: null,
                    lastName: null,
                    gender: null,
                    dateOfBirth: null,
                    profile: null,
                    educationSystems: [],
                    educationLevels: [],
                    educationGrades: [],
                    subjects: [],
                    emailAddress: null,
                    countryCode: null,
                    biography: null,
                    lastSeen: null,
                    classes: [],
                    days: [],
                    teachingModes: [],
                    rating: null,
                    viewsCount: null,
                    createdAt: null,
                    updatedAt: null,
                },

				currentPage: 1,

      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Tutors', url: '/tutors', isActive: false },
                { title: 'Profile', url: '', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.getTutorProfile()
		},
		methods: {

            copyUrl() {
                let link = `${this.$FRONTEND_URL}${this.$route.path}`;
                
                navigator.clipboard.writeText(link);

                this.notify('Link has been copied successfully', 'success')
            },

            toBookingPage() {
                this.$router.push(`/tutors/view/${this.classDetails.uuid}/booking`)
            },

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			async getTutorProfile() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/profiles?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classDetails.uuid = response.data.data.uuid;
                        this.classDetails.firstName = response.data.data.firstName;
                        this.classDetails.lastName = response.data.data.lastName;
                        this.classDetails.gender = response.data.data.gender;
                        this.classDetails.dateOfBirth = response.data.data.dateOfBirth;
                        this.classDetails.profile = response.data.data.profile;
                        this.classDetails.educationSystems = response.data.data.educationSystems;
                        this.classDetails.educationLevels = response.data.data.educationLevels;
                        this.classDetails.educationGrades = response.data.data.educationGrades;
                        this.classDetails.subjects = response.data.data.subjects;
                        this.classDetails.emailAddress = response.data.data.emailAddress;
                        this.classDetails.countryCode = response.data.data.countryCode;
                        this.classDetails.biography = response.data.data.biography;
                        this.classDetails.days = response.data.data.days;
                        this.classDetails.teachingModes = response.data.data.teachingModes;
                        this.classDetails.lastSeen = response.data.data.lastSeen;
                        this.classDetails.rating = response.data.data.rating;
                        this.classDetails.viewsCount = response.data.data.viewsCount;
                        this.classDetails.createdAt = response.data.data.createdAt;
                        this.classDetails.updatedAt = response.data.data.updatedAt;
                        this.classDetails.classes = await this.filterClasses(response.data.data.classes, response.data.data.uuid);
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            filterClasses(classes, uuid) {
                return classes != null && classes.length > 0 ? classes.filter(clas => clas.creator != null && clas.creator.uuid == uuid) : []
            },

            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                }); 
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>


<!-- {
    